import { apiBaseQuery } from '../../api/services/rtkQuery/apiBaseQuery';
import { getGameRoomsQuery, getInitGameQuery } from './queries';
export var gamesApi = apiBaseQuery.injectEndpoints({
  endpoints: function endpoints(builder) {
    return {
      getGameRooms: builder.query(getGameRoomsQuery),
      getInitGame: builder.query(getInitGameQuery)
    };
  }
});
gamesApi.enhanceEndpoints({
  addTagTypes: ['GetGameRooms']
});
var _gamesApi$endpoints = gamesApi.endpoints,
  getGameRooms = _gamesApi$endpoints.getGameRooms,
  getInitGame = _gamesApi$endpoints.getInitGame;
export { getGameRooms, getInitGame };