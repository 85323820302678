import _defineProperty from "/var/jenkins/workspace/Consolidated/Code/Dev/Web@2/platforms/web/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _schemas;
import { Endpoints } from '../../../api/Constants';
import getFbAppId from './getFbAppId';
import getTermsAndConditions from './getTermsAndConditions';
import getProgressiveJackpots from './getProgressiveJackpots';
import getAssets from './getAssets';
import getMissions from './getMissions';
import postMissions from './postMissions';
import initProperty from './initProperty';
import dailyWheel from './dailyWheel';
import getGameRooms from './getGameRooms';
import postLinkGuestAccount from './postLinkGuestAccount';
import getEvents from './getEvents';
import initRewards from './initRewards';
import getFriendsInvite from './getFriendsInvite';
var schemas = (_schemas = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_schemas, Endpoints.GET_FB_APP_ID, {
  GET: getFbAppId
}), Endpoints.TERMS_AND_CONDITIONS, {
  GET: getTermsAndConditions
}), Endpoints.PROGRESSIVE_JACKPOTS, {
  GET: getProgressiveJackpots
}), Endpoints.ASSETS, {
  GET: getAssets
}), Endpoints.MISSIONS_PLAYER, {
  GET: getMissions,
  POST: postMissions
}), Endpoints.PROPERTIES, {
  GET: initProperty
}), Endpoints.GET_DAILY_WHEEL_INFO, {
  GET: dailyWheel
}), Endpoints.GET_GAME_ROOMS, {
  GET: getGameRooms
}), Endpoints.LINK_GUEST_ACCOUNT_EMAIL, {
  POST: postLinkGuestAccount
}), Endpoints.LINK_GUEST_ACCOUNT_GOOGLE, {
  POST: postLinkGuestAccount
}), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_schemas, Endpoints.LINK_GUEST_ACCOUNT_APPLE, {
  POST: postLinkGuestAccount
}), Endpoints.LINK_GUEST_ACCOUNT_FACEBOOK, {
  POST: postLinkGuestAccount
}), Endpoints.EVENTS_PLAYER, {
  GET: getEvents
}), Endpoints.INIT_REWARDS, {
  GET: initRewards
}), Endpoints.FRIENDS_INVITE, {
  GET: getFriendsInvite
}));
export var getSchema = function getSchema(id, method) {
  return schemas[id][method];
};