module.exports = {
  dimensions: {
    width: 900,
    height: 57
  },
  nine: {
    top: 28,
    left: 40,
    right: 40,
    bottom: 3
  },
  optical: {
    top: 1,
    left: 1,
    right: 1,
    bottom: 1
  }
};